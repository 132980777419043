<template>
  <router-link to="plans" class="card card-custom card-stretch gutter-b">
    <div class="card-body d-flex p-0">
      <div class="d-flex flex-center bg-custom flex-grow-1  p-5 card-rounded">
        <div style="width:100%" v-if="message.title">
          <p class="text-white mt-2 call-title " v-html="message.title"></p>
          <p
            class="text-white mt-2 call-description"
            v-html="message.description"
          ></p>
        </div>
        <div>
          <span class="svg-icon svg-icon-3x ml-4 mb-1"
            ><!--begin::Svg Icon | path:/var/www/preview.keenthemes.com/metronic/releases/2021-05-14-112058/theme/html/demo1/dist/../src/media/svg/icons/Communication/Outgoing-box.svg--><svg
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              width="24px"
              height="24px"
              viewBox="0 0 24 24"
              version="1.1"
            >
              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <rect x="0" y="0" width="24" height="24" />
                <path
                  d="M22,17 L22,21 C22,22.1045695 21.1045695,23 20,23 L4,23 C2.8954305,23 2,22.1045695 2,21 L2,17 L6.27924078,17 L6.82339262,18.6324555 C7.09562072,19.4491398 7.8598984,20 8.72075922,20 L15.381966,20 C16.1395101,20 16.8320364,19.5719952 17.1708204,18.8944272 L18.118034,17 L22,17 Z"
                  fill="#000000"
                />
                <path
                  d="M2.5625,15 L5.92654389,9.01947752 C6.2807805,8.38972356 6.94714834,8 7.66969497,8 L16.330305,8 C17.0528517,8 17.7192195,8.38972356 18.0734561,9.01947752 L21.4375,15 L18.118034,15 C17.3604899,15 16.6679636,15.4280048 16.3291796,16.1055728 L15.381966,18 L8.72075922,18 L8.17660738,16.3675445 C7.90437928,15.5508602 7.1401016,15 6.27924078,15 L2.5625,15 Z"
                  fill="#000000"
                  opacity="0.3"
                />
                <path
                  d="M11.1288761,0.733697713 L11.1288761,2.69017121 L9.12120481,2.69017121 C8.84506244,2.69017121 8.62120481,2.91402884 8.62120481,3.19017121 L8.62120481,4.21346991 C8.62120481,4.48961229 8.84506244,4.71346991 9.12120481,4.71346991 L11.1288761,4.71346991 L11.1288761,6.66994341 C11.1288761,6.94608579 11.3527337,7.16994341 11.6288761,7.16994341 C11.7471877,7.16994341 11.8616664,7.12798964 11.951961,7.05154023 L15.4576222,4.08341738 C15.6683723,3.90498251 15.6945689,3.58948575 15.5161341,3.37873564 C15.4982803,3.35764848 15.4787093,3.33807751 15.4576222,3.32022374 L11.951961,0.352100892 C11.7412109,0.173666017 11.4257142,0.199862688 11.2472793,0.410612793 C11.1708299,0.500907473 11.1288761,0.615386087 11.1288761,0.733697713 Z"
                  fill="#000000"
                  fill-rule="nonzero"
                  transform="translate(11.959697, 3.661508) rotate(-90.000000) translate(-11.959697, -3.661508) "
                />
              </g></svg
            ><!--end::Svg Icon--></span
          >
        </div>
      </div>
    </div>
  </router-link>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "upgrade-plan",
  props: ["plan"],
  computed: {
    ...mapGetters(["currentUser"]),
    days() {
      const created = new Date(this.currentUser.createdAt);
      const now = new Date();
      const diffTime = Math.abs(now - created);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      return diffDays;
    },
    message() {
      let message = {};
      if (this.currentUser.plan.name === "Trial") {
        if (this.days < 30) {
          message = {
            title: `O teste gratuito termina em  <b>${30 - this.days} dias</b>`,
            description:
              "Em breve seu período de degustação do plano <b>Entreprise</b> irá se esgotar, não perca a oportunidade e faça agora mesmo o upgrade do seu plano e tenha benefícios exclusivo de assinantes."
          };
        }
        if (this.days >= 30) {
          message = {
            title: "O teste gratuito do <b>acabou</b>",
            description: `
            Identificamos que sua conta possui mais de 1 domínio/subdomínio no plano Start, o que é fora das regras do plano gratuito.<br>
            Caso deseje manter todos os domínios já registrado faça agora mesmo o upgrade do seu plano e tenha benefícios exclusivo de assinantes.<br><br>
            Os domínios remanescentes serão automaticamente arquivados até o dia <b>30/09/2021</b>.
            `
          };
        }
      }
      return message;
    }
  }
};
</script>

<style scoped>
.call-title {
  font-size: 18px;
}
.call-description {
  text-align: justify;
}
.bg-custom {
  background-color: #1c444e;
  transition: 0.2s;
}

.bg-custom:hover {
  background-color: #023542;
}
</style>
