<template>
  <div class="col-6">
    <div class="card card-custom ">
      <div class="card-header border-0">
        <h3 class="card-title font-weight-bolder text-dark">
          2º - Confirmação de downgrade
        </h3>
      </div>
      <div class="card-body pt-2">
        <!-- ACCOUNT ITEM -->
        <p class="card-title font-weight-bolder text-dark">
          Sites selecionados
        </p>
        <div>
          <span
            class="label label-primary label-inline font-weight-lighter mr-2"
            v-for="account in selected"
            :key="account._id"
            >{{ account.website }}</span
          >
        </div>
        <button
          :class="`btn btn-danger mt-10 ${loading}`"
          @click="downgrade"
          :disabled="disabled"
        >
          Solicitar Downgrade
        </button>
        <!--  END ACCOUNT ITEM -->
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import ApiService from "@/core/services/api.service";
import { UPDATE_USER } from "@/core/services/store/auth.module";
import { mapGetters } from "vuex";
export default {
  props: ["list", "selected", "plan"],
  data() {
    return {
      loading: "",
      disabled: false
    };
  },
  computed: {
    ...mapGetters(["currentUser"]),
    accounts() {
      return this.selected.map(account => {
        return account._id;
      });
    }
  },
  methods: {
    downgrade() {
      this.disabled = true;
      this.loading = "spinner spinner-white spinner-right";
      let data = {
        user: this.$store.state.auth.user._id,
        accounts: this.accounts,
        plan: this.$route.params.plan
      };
      ApiService.post(`downgrade`, data).then(() => {
        ApiService.post(`/users/subscribe`, {
          plan: this.plan._id,
          iugu: this.currentUser.iugu
        })
          .then(() => {
            let user = this.currentUser;
            user.plan = this.plan;
            this.$store.dispatch(UPDATE_USER, user);
            Swal.fire({
              title: "Deu certo!",
              text: "Downgrade solicitado com sucesso!",
              icon: "success",
              confirmButtonClass: "btn btn-secondary"
            }).then(() => {
              this.$router.push("/");
            });
            this.loading = "";
            this.disabled = false;
          })
          .catch(() => {
            Swal.fire({
              title: "Ops!",
              text: "Tivemos algum problema para processar essa interação.",
              icon: "error",
              confirmButtonClass: "btn btn-danger"
            });
            this.loading = "";
            this.disabled = false;
          });
      });
    }
  }
};
</script>

<style></style>
