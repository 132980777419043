<template>
  <div class="col-6">
    <div class="card card-custom ">
      <div class="card-header border-0">
        <h3 class="card-title font-weight-bolder text-dark">
          1º - {{ $tc(`DOWNGRADE.LIST`, maxAccounts, { count: maxAccounts }) }}
        </h3>
      </div>
      <div class="card-body pt-2">
        <!-- ACCOUNT ITEM -->
        <div
          class="d-flex align-items-center mb-10"
          :class="{ disabled: isDisabled(account._id) }"
          v-for="(account, index) in list"
          :key="index"
        >
          <span class="bullet bullet-bar align-self-stretch bg-success"></span>
          <label
            class="checkbox checkbox-lg checkbox-single flex-shrink-0 m-0 mx-4 checkbox-light-success"
          >
            <input
              type="checkbox"
              class=" disabled"
              v-model="listSelected"
              :value="account"
              :disabled="isDisabled(account._id)"
            />
            <span></span>
          </label>
          <div class="d-flex flex-column flex-grow-1">
            <a
              class="text-dark-75 text-hover-primary font-weight-bold font-size-lg mb-1 text-uppercase"
            >
              {{ account.name }} </a
            ><span class="text-muted font-weight-bold">
              {{ account.website }}
            </span>
          </div>
        </div>
        <!--  END ACCOUNT ITEM -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["list", "selected", "plan"],
  data() {
    return {
      listSelected: []
    };
  },
  created() {},
  computed: {
    maxAccounts() {
      return this.plan.domains;
    },
    selecteds() {
      return this.listSelected.map(account => {
        return account._id;
      });
    }
  },
  methods: {
    isDisabled(id) {
      const found = this.selecteds.find(element => element === id);
      if (found) return false;
      if (this.listSelected.length === this.maxAccounts && !found) return true;
    }
  },
  watch: {
    listSelected: function(val) {
      this.$events.emit("accounts-selected", val);
    }
  }
};
</script>

<style>
.disabled {
  text-decoration: line-through #f64e60;
  color: #f64e60;
}
.disabled .checkbox > span {
  background-color: #f64e5f75;
}
.disabled .bg-success {
  background-color: #f64e60 !important;
}
</style>
