<template>
  <div class="navegation">
    <template v-if="upgrade">
      <div class="row">
        <div class="col-xl-12">
          <Upgrade :plan="nextPlan"></Upgrade>
        </div>
      </div>
    </template>
    <div class="row">
      <div class="col-xl-12">
        <Sessions></Sessions>
      </div>
    </div>
  </div>
</template>

<script>
import Sessions from "@/view/content/widgets/list/Sessions.vue";
import Upgrade from "@/view/content/widgets/engage/upgrade.vue";
import { mapGetters } from "vuex";
export default {
  name: "navigation",
  components: {
    Sessions,
    Upgrade
  },
  data() {
    return {
      plansSequence: {
        1: "Plano Professional",
        2: "Plano Enterprise",
        3: "Você está no nosso maior plano"
      }
    };
  },
  computed: {
    ...mapGetters(["currentUser"]),
    upgrade() {
      return this.currentUser.plan.name === "Trial";
    },
    nextPlan() {
      return this.plansSequence[this.currentUser.plan.sequence];
    }
  }
};
</script>

<style></style>
