<template>
  <div>
    <Navigation></Navigation>
    <div class="row">
      <div class="col-12">
        <div
          class="card card-custom bgi-no-repeat bgi-size-cover gutter-b bg-danger "
          style="height: 250px; background-image: url(./media/svg/patterns/taieri.svg)"
        >
          <div class="card-body d-flex" v-if="plan">
            <div class="d-flex py-5 flex-column align-items-start flex-grow-1">
              <div class="flex-grow-1">
                <p class="text-white font-weight-bolder font-size-h1">
                  Downgrade de plano
                </p>
                <p
                  class="text-white opacity-75 font-weight-bold mt-10 font-size-h3"
                >
                  Você tem certeza que deseja realizar o downgrade do seu plano
                  para o
                  <strong>{{ name }}</strong
                  >?
                </p>
                <router-link
                  to="/dashboard"
                  class="btn btn-success text-uppercase mt-5"
                >
                  Quero manter meu plano atual
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <list :list="list" :selected="selected" :plan="plan"></list>
      <selected :list="list" :selected="selected" :plan="plan"></selected>
    </div>
  </div>
</template>

<script>
import Navigation from "@/view/layout/navigation";
import list from "./comps/list";
import selected from "./comps/selected";
import ApiService from "@/core/services/api.service";

export default {
  components: {
    list,
    selected,
    Navigation
  },
  data() {
    return {
      list: [],
      selected: [],
      plan: {}
    };
  },
  mounted() {
    this.getAccounts();
    this.getPlan();
    this.$events.off("accounts-selected");
    this.$events.on("accounts-selected", data => {
      this.selected = data;
    });
  },
  computed: {
    name() {
      return this.plan.name;
    }
  },
  methods: {
    getPlan() {
      ApiService.query(`plans/${this.$route.params.plan}`)
        .then(({ data }) => {
          this.plan = data;
        })
        .catch(() => {});
    },
    getAccounts() {
      ApiService.setHeader();
      let userId = this.$store.state.auth.user._id;
      ApiService.query(`users/${userId}/accounts`)
        .then(({ data }) => {
          //console.log("USER", data);
          // this.processList(data);
          this.list = data;
        })
        .catch(() => {
          //console.log("USER ERROR", response);
        });
    }
  }
};
</script>

<style></style>
